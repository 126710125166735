import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LStrSelect(props) {
    const { onChange, doc, endpoints, defaultValue, nodeId, setValidation, disableValidation } = props;
    const { endpoint, key_value, key_label } = doc;

    const [updated, setUpdated] = useState(true);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (!Array.isArray(defaultValue) || defaultValue.length !== 1) return;

        const options = (endpoints[endpoint] || {}).data || [];
        const defaultEntry = options.find((entry) => entry[key_value] === defaultValue[0]);

        if (defaultEntry !== undefined) {
            setValue(defaultEntry);
            setInputValue(defaultEntry[key_label]);
        } else {
            const customValue = { [key_value]: defaultValue[0], [key_label]: defaultValue[0] };
            setValue(customValue);
            setInputValue(defaultValue[0]);
        }
    }, [defaultValue, endpoints, key_value, key_label, endpoint]);

    const handleValueChange = (event, newValue, reason) => {
        if (!disableValidation) setValidation({ nodeId, validate: false });

        // Custom value
        if (typeof newValue === 'string') {
            setValue({ [key_value]: newValue, [key_label]: newValue });
        }
        // Autocomplete value
        else if (newValue && newValue.inputValue) {
            setValue({ [key_value]: newValue.inputValue, [key_label]: newValue.inputValue });
        }
        // Default value
        else {
            setValue(newValue);
        }

        setInputValue(newValue ? newValue[key_label] || newValue : '');
        setUpdated(true);
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (!disableValidation) setValidation({ nodeId, validate: false });
        setUpdated(true);
    };

    const handleValidationClick = () => {
        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([value[key_value]]);
    };

    const getOptionLabel = (option) => {
        if (option === null) return '';
        return option[key_label] || option;
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <Autocomplete
                    freeSolo
                    autoComplete
                    autoHighlight
                    autoSelect
                    value={value}
                    onChange={handleValueChange}
                    onInputChange={handleInputChange}
                    getOptionLabel={getOptionLabel}
                    options={endpoints[endpoint] !== undefined ? endpoints[endpoint].data : []}
                    style={{ width: 400 }}
                    renderInput={(params) => <TextField {...params} label='Available options' fullWidth />}
                    data-testid='lstr-select'
                />
            </Grid>
            <Grid item>
                <ValidationButton
                    error={
                        value === null && (!inputValue || (typeof inputValue === 'string' && inputValue.trim() === ''))
                    }
                    onClick={handleValidationClick}
                    updated={updated}
                    disabled={!inputValue || (typeof inputValue === 'string' && inputValue.trim() === '')}
                />
            </Grid>
        </BodyBuilderGrid>
    );
}

LStrSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    doc: PropTypes.object.isRequired,
    endpoints: PropTypes.object.isRequired,
    nodeId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    endpoints: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LStrSelect);
