import { Add } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LDynamicInput(props) {
    const { onChange, nodeId, setValidation, doc, endpoints, defaultValue, errorToast, disableValidation } = props;
    const { endpoint } = doc;

    const [updated, setUpdated] = useState(true);
    const [values, setValues] = useState(defaultValue || []);
    const [inputs, setInputs] = useState([]);
    const [completion, setCompletion] = useState({});
    const [visibleCount, setVisibleCount] = useState(1);

    useEffect(() => {
        const data = endpoints[endpoint].data;

        if (!data) return;

        setInputs(data?.params || []);
        setCompletion(data?.data);
    }, [endpoint, endpoints]);

    useEffect(() => {
        if (defaultValue.length) {
            setVisibleCount(defaultValue.length);
        }
    }, [defaultValue]);

    const handleValidationClick = () => {
        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);

        // Remove empty values at the end of the array
        let newValues = [...values];

        // Truncate the array to the length of the inputs
        newValues = newValues.slice(0, inputs.length);

        while (newValues.length > 0 && !newValues[newValues.length - 1]) {
            newValues.pop();
        }

        if (newValues.length === 0) {
            if (!disableValidation) setValidation({ nodeId, validate: false });
            setUpdated(true);
            errorToast('Please select a value');
            return;
        }

        // Check if a value is empty
        if (newValues.some((value) => !value)) {
            if (!disableValidation) setValidation({ nodeId, validate: false });
            setUpdated(true);
            const errorLocation = newValues.findIndex((value) => !value);
            errorToast(`Please select a ${inputs[errorLocation]} or remove the selected values`);
            return;
        }

        onChange(newValues);
    };

    const handleInputChange = (index, event, newValue) => {
        const newValues = [...values];
        newValues[index] = newValue || event?.target?.value;

        for (let i = index + 1; i < newValues.length; i++) {
            newValues[i] = '';
        }

        setValues(newValues);
        setUpdated(true);
        if (!disableValidation) setValidation({ nodeId, validate: false });
    };

    const getOptionLabel = (option) => {
        if (option === null) return '';
        return option;
    };

    const handleAddClick = () => {
        setVisibleCount((prevCount) => prevCount + 1);
    };

    const getCompletionOptions = useCallback(
        (index) => {
            if (!completion) return [];

            let completionData = completion;

            for (let i = 0; i < index; i++) {
                if (!completionData[values[i]]) {
                    return [];
                }

                completionData = completionData[values[i]];
            }

            if (!Array.isArray(completionData)) {
                completionData = Object.keys(completionData);
            }

            return completionData;
        },
        [completion, values],
    );

    return (
        <BodyBuilderGrid>
            {inputs.slice(0, visibleCount).map((input, index) => (
                <Grid item key={index}>
                    <Autocomplete
                        freeSolo
                        autoComplete
                        autoHighlight
                        autoSelect
                        value={values[index] || ''}
                        onChange={(event, newValue) => handleInputChange(index, event, newValue)}
                        onInputChange={(event, newInputValue) => handleInputChange(index, event, newInputValue)}
                        getOptionLabel={getOptionLabel}
                        options={getCompletionOptions(index)}
                        data-testid={`lstr-select-${index}`}
                        renderInput={(params) => (
                            <Box sx={{ width: { xs: '100%', sm: 300, md: 150, lg: 200 } }}>
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={input}
                                    fullWidth
                                    sx={{ fontSize: '1rem' }}
                                />
                            </Box>
                        )}
                    />
                </Grid>
            ))}
            {inputs.length ? (
                <>
                    {visibleCount < inputs.length && (
                        <Grid item>
                            <IconButton onClick={handleAddClick} aria-label='add' data-testid='plus-button'>
                                <Add />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid item>
                        <ValidationButton onClick={handleValidationClick} updated={updated} />
                    </Grid>
                </>
            ) : (
                <Grid item sx={{ marginTop: '16px' }}>
                    <CircularProgress size={24} />
                </Grid>
            )}
        </BodyBuilderGrid>
    );
}

LDynamicInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
    doc: PropTypes.object.isRequired,
    endpoints: PropTypes.object.isRequired,
    errorToast: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    endpoints: state.data,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
        errorToast: (message) => dispatch(actions.notification.add.failure(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LDynamicInput);
