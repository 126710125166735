import {
    SignalCellular0Bar,
    SignalCellular1Bar,
    SignalCellular2Bar,
    SignalCellular3Bar,
    SignalCellular4Bar,
    SignalCellularConnectedNoInternet0Bar,
    SignalCellularOff,
} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

export let healthResponseMaxDelay;

function BackendStatus(props) {
    const { checkBackendHealth, isCheckingHealth, error, responseTime, refreshInterval, title } = props;

    const theme = useTheme();
    const timeout = healthResponseMaxDelay ? healthResponseMaxDelay : 5000;

    const centerIconCss = { display: 'inline', verticalAlign: 'middle', margin: '0 4px' };

    const styles = {
        success: {
            ...centerIconCss,
            color: theme.palette.secondary.main,
        },
        warning: {
            ...centerIconCss,
            color: theme.palette.warning.main,
        },
        error: {
            ...centerIconCss,
            color: theme.palette.error.main,
        },
    };

    // [0, 1, 2, 3, 4, 5]: higher is the value, better is the connection quality
    const connectionQuality = responseTime === null ? null : Math.round((1.0 - responseTime / timeout) / (1.0 / 4.0));

    useEffect(() => {
        if (isCheckingHealth) return;
        const interval = setInterval(checkBackendHealth, refreshInterval);
        return () => clearInterval(interval);
    }, [isCheckingHealth, checkBackendHealth, refreshInterval]);

    if (connectionQuality === null || error) {
        return (
            <Tooltip title={`${title} does not respond`}>
                <SignalCellularOff sx={styles.error} />
            </Tooltip>
        );
    }

    if (connectionQuality < 0) {
        return (
            <Tooltip title={`${title} api timeout (${responseTime.toFixed(2)} ms)`}>
                <SignalCellularConnectedNoInternet0Bar sx={styles.error} />
            </Tooltip>
        );
    }

    return (
        <Tooltip title={`${title} api delay: ${responseTime.toFixed(2)} ms`}>
            {(connectionQuality === 0 && <SignalCellular0Bar sx={styles.error} />) ||
                (connectionQuality === 1 && <SignalCellular1Bar sx={styles.warning} />) ||
                (connectionQuality === 2 && <SignalCellular2Bar sx={styles.warning} />) ||
                (connectionQuality === 3 && <SignalCellular3Bar sx={styles.success} />) ||
                (connectionQuality === 4 && <SignalCellular4Bar sx={styles.success} />) ||
                null}
        </Tooltip>
    );
}

// Required argument
BackendStatus.propTypes = {
    responseTime: PropTypes.any,
    isCheckingHealth: PropTypes.any.isRequired,
    refreshInterval: PropTypes.any.isRequired,
    checkBackendHealth: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
};

export default BackendStatus;
