import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { validateBase64List, validateBoolean } from '../../utils/BasicValidation';
import { validateSelectedTimeRange } from '../../utils/DateRangeFilters';
import { OTHER_ROW, URL_PARAM_NAMES, validateChartType, validateFilter } from '../utils';
import { AttributesChart } from './ChartAttributes';

export const ChartUrlStateHandler = (props) => {
    // Saved states
    const [searchParams, setSearchParams] = useSearchParams();

    const [chartType, setChartType] = useState(validateChartType(searchParams.get(URL_PARAM_NAMES.chartType)));
    const [isStacked, setIsStacked] = useState(validateBoolean(searchParams.get(URL_PARAM_NAMES.isStacked)));
    const [selectedKeys, setSelectedKeys] = useState(
        validateBase64List(searchParams.get(URL_PARAM_NAMES.selectedKeys)),
    );

    const [selectedLabel, setSelectedLabel] = useState(searchParams.get(URL_PARAM_NAMES.selectedLabel));
    const [selectedName, setSelectedName] = useState(searchParams.get(URL_PARAM_NAMES.selectedName));
    const [filter, setFilter] = useState(validateFilter(searchParams.get(URL_PARAM_NAMES.filter)));
    const [selectedTimeRange, setSelectedTimeRange] = useState(
        validateSelectedTimeRange(searchParams.get(URL_PARAM_NAMES.selectedTimeRange)),
    );
    const [autoSelect, setAutoSelect] = useState(validateBoolean(searchParams.get(URL_PARAM_NAMES.autoSelect)));
    const [otherRow, setOtherRow] = useState(validateBoolean(searchParams.get(URL_PARAM_NAMES.otherRow)));

    // Update URL params
    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };

        newParams[URL_PARAM_NAMES.selectedLabel] = selectedLabel;
        newParams[URL_PARAM_NAMES.selectedName] = selectedName;
        newParams[URL_PARAM_NAMES.filter] = filter;
        newParams[URL_PARAM_NAMES.selectedTimeRange] = selectedTimeRange;
        newParams[URL_PARAM_NAMES.chartType] = chartType;
        newParams[URL_PARAM_NAMES.isStacked] = isStacked.toString();
        newParams[URL_PARAM_NAMES.selectedKeys] = btoa(JSON.stringify(selectedKeys.filter((key) => key !== OTHER_ROW)));
        newParams[URL_PARAM_NAMES.autoSelect] = autoSelect.toString();
        newParams[URL_PARAM_NAMES.otherRow] = otherRow.toString();

        if (selectedLabel === null) delete newParams[URL_PARAM_NAMES.selectedLabel];
        if (selectedName === null) delete newParams[URL_PARAM_NAMES.selectedName];
        if (filter === null) delete newParams[URL_PARAM_NAMES.filter];
        if (selectedTimeRange === null) delete newParams[URL_PARAM_NAMES.selectedTimeRange];
        if (chartType === null) delete newParams[URL_PARAM_NAMES.chartType];

        setSearchParams(newParams, { replace: true });
    }, [
        selectedLabel,
        selectedName,
        filter,
        selectedTimeRange,
        setSearchParams,
        searchParams,
        chartType,
        isStacked,
        selectedKeys,
        autoSelect,
        otherRow,
    ]);

    return (
        <AttributesChart
            {...props}
            chartType={chartType}
            setChartType={setChartType}
            isStacked={isStacked}
            setIsStacked={setIsStacked}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            selectedName={selectedName}
            setSelectedName={setSelectedName}
            filter={filter}
            setFilter={setFilter}
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            autoSelect={autoSelect}
            setAutoSelect={setAutoSelect}
            otherRow={otherRow}
            setOtherRow={setOtherRow}
        />
    );
};
