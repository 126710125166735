import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';

import { capitalizeFirstLetter } from '../../../components/Attributes/Switch/AttributeUtils';

function AttributeFilters(props) {
    const {
        attrName,
        attrValue,
        handleAttrNameChange,
        handleAttrValueChange,
        handleSearch,
        availableAttrNames,
        availableAttrValues,
        type,
    } = props;

    const getAttributeName = (name) => {
        const changesMap = {
            segments: 'attribute',
            labels: 'label',
        };
        return changesMap[name] || name;
    };

    return (
        <Grid container spacing={2} alignItems='center' justifyContent='center'>
            <Grid item sm={5} md={4} xl={3}>
                <Autocomplete
                    freeSolo
                    autoComplete
                    autoHighlight
                    autoSelect
                    value={attrName}
                    onChange={handleAttrNameChange}
                    onInputChange={handleAttrNameChange}
                    options={availableAttrNames || []}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`${capitalizeFirstLetter(getAttributeName(type))}  Name`}
                            placeholder='Enter a name'
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item sm={5} md={4} xl={3}>
                <Autocomplete
                    freeSolo
                    autoComplete
                    autoHighlight
                    autoSelect
                    value={attrValue}
                    onChange={handleSearch}
                    onInputChange={handleAttrValueChange}
                    options={availableAttrValues || []}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`${capitalizeFirstLetter(getAttributeName(type))} Value`}
                            placeholder='Enter a value'
                            fullWidth
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}

export default AttributeFilters;
