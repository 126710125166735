import { call, put, takeLatest } from '@redux-saga/core/effects';

import { actions } from '../actions';
import { api } from '../services/api';
import { retrySaga } from './apiWrapper';

let timeout = null;
let firstCall = true;
const frontVersionKey = 'frontend_version';
const timeBeforeReloadInSec = 60 * 5;

const checkFrontendVersion = (version) => {
    const savedVersion = localStorage.getItem(frontVersionKey);

    if (!savedVersion || firstCall) {
        localStorage.setItem(frontVersionKey, version);
        firstCall = false;
        return false;
    }

    firstCall = false;

    if (savedVersion !== version) {
        if (timeout) {
            console.log('Refresh already scheduled, skipping');
            return true;
        }

        console.log(
            `New version detected (${savedVersion} -> ${version}), scheduling refresh in ${timeBeforeReloadInSec} sec`,
        );

        timeout = setTimeout(() => {
            window.location.reload();
            timeout = null;
            localStorage.setItem(frontVersionKey, version);
        }, timeBeforeReloadInSec * 1000);

        return true;
    } else if (timeout) {
        clearTimeout(timeout);
        timeout = null;
        console.log('The version is the same, canceling reload');
    }

    return false;
};

function* checkHealth() {
    try {
        const t0 = performance.now();
        const response = yield call(retrySaga, api.health);
        const dbVersion = response?.data?.db_version;
        const cvsVersion = response?.data?.cvs_version;
        const annProcessing = response?.data?.ann_processing;
        const notif = response?.data?.notif;
        const t1 = performance.now();
        yield put(actions.api.health.success({ responseTime: t1 - t0, dbVersion, cvsVersion, annProcessing, notif }));
        checkFrontendVersion(dbVersion);
    } catch (e) {
        yield put(actions.api.health.failure(e));
    }
}

function* checkDataHealth() {
    try {
        const t0 = performance.now();
        yield call(retrySaga, api.dataHealth);
        const t1 = performance.now();
        yield put(actions.api.data_health.success(t1 - t0));
    } catch (e) {
        yield put(actions.api.data_health.failure(e));
    }
}

export default function* watchHealth() {
    yield takeLatest(actions.api.health.request.toString(), checkHealth);
    yield takeLatest(actions.api.data_health.request.toString(), checkDataHealth);
}
