import { useTheme } from '@emotion/react';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

import { timeRanges } from '../../utils/DateRangeFilters';
import { getGraphConfigStyles } from '../styles/StyleComponent';
import { isArea } from '../utils';

const GraphConfig = (props) => {
    const {
        chartType,
        setChartType,
        isStacked,
        setIsStacked,
        autoSelect,
        handleAutoSelect,
        otherRow,
        setOtherRow,
        selectedTimeRange,
        setSelectedTimeRange,
        minDate,
    } = props;

    const theme = useTheme();
    const styles = getGraphConfigStyles(theme);

    const handleTimeRange = (event, newTimeRange) => {
        if (newTimeRange !== null) {
            setSelectedTimeRange(newTimeRange);
        }
    };

    const handleChartType = (event, newChartType) => {
        if (newChartType !== null) {
            setChartType(newChartType);
        }
    };

    return (
        <Stack direction='row' spacing={4} alignItems='center' sx={styles.center}>
            <ToggleButtonGroup
                value={chartType}
                exclusive
                onChange={handleChartType}
                aria-label='time range'
                size='small'
                sx={styles.buttonGroup}
            >
                <ToggleButton value='area'>Area</ToggleButton>
                <ToggleButton value='line'>Line</ToggleButton>
            </ToggleButtonGroup>
            {isArea(chartType) && (
                <ToggleButton
                    value='check'
                    size='small'
                    selected={isStacked}
                    onChange={() => setIsStacked(!isStacked)}
                    sx={{ border: 'none' }}
                >
                    Stacked
                </ToggleButton>
            )}
            <ToggleButton
                value='check'
                size='small'
                selected={autoSelect}
                onChange={() => handleAutoSelect()}
                sx={{ border: 'none' }}
            >
                Auto Select
            </ToggleButton>
            <ToggleButton
                value='check'
                size='small'
                selected={otherRow}
                onChange={() => setOtherRow(!otherRow)}
                sx={{ border: 'none' }}
            >
                Other
            </ToggleButton>
            <ToggleButtonGroup
                value={selectedTimeRange}
                exclusive
                onChange={handleTimeRange}
                aria-label='time range'
                size='small'
                sx={styles.buttonGroup}
            >
                {Object.keys(timeRanges).map((range) => {
                    const date = timeRanges[range]();
                    if (date < minDate && range !== 'All Time') {
                        return null;
                    }
                    return (
                        <ToggleButton key={range} value={range} aria-label={range.toLowerCase()}>
                            {range}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </Stack>
    );
};

export default GraphConfig;
