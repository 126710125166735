import { Add as AddIcon, Delete as DeleteIcon, Remove as RemoveIcon } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip as TooltipMui,
    Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import { denormalizeTree } from '../../../../../reducers/qbuilder';
import { useInterval } from '../../../../../services/helpers';
import EnhancedTableToolbar from '../../../../components/Table/EnhancedTableToolbar';
import LocalSegmentQueryBuilder from '../../../QBuilder/components/LocalSegmentQBuilder';
import { getPanelUri } from '../../../index';
import ProjectPanel from '../../index';

const DEFAULT_BODY_HEIGHT = 168;
const URL_SAVE_INTERVAL = 5 * 1000;
const VALIDATION_BUTTONS = '.validation-button-query';
const VALIDATE_BUTTONS_TIMEOUT = 1000;
const VALIDATION_MAX_RETRY = 10;
const DEFAULT_TABLE_VALUE = [{ title: 'New Table', data: [{ subject: '', query: [] }] }];
const ProjectForm = (props) => {
    const { submitProject, editMode, config } = props;
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();

    const styles = {
        paper: {
            width: '100%',
            padding: '0 16px 8px 16px',
            marginBottom: theme.spacing(1),
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            minWidth: '600px',
            maxHeight: `calc(100vh - ${DEFAULT_BODY_HEIGHT}px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        table: {
            minWidth: 650,
            marginTop: theme.spacing(3),
        },
        tableCell: {
            textAlign: 'center',
            verticalAlign: 'top',
            padding: theme.spacing(1),
        },
        buttonContainer: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        center: {
            width: '100%',
            textAlign: 'center',
        },
        queryItem: {
            marginTop: theme.spacing(3),
        },
        section: {
            backgroundColor: theme.palette.common.sectionGray,
            margin: `4px 0 20px 0`,
            borderRadius: '5px',
            padding: theme.spacing(3),
        },
        sectionTitle: {
            marginBottom: theme.spacing(2),
        },
        separator: {
            border: `1px solid ${theme.palette.divider}`,
            width: '100%',
        },
        name: {
            marginBottom: theme.spacing(3),
        },
        textfiled: {
            marginTop: '8px',
        },
    };

    const [projectName, setProjectName] = useState('');
    const [projectQuery, setProjectQuery] = useState([]);
    const [areQueryValidated, setAreQueryValidated] = useState(true);
    const validationTimeoutRef = useRef(null);
    const validationRetry = useRef(0);

    const [projectQueryId, setProjectQueryId] = useState(uuidv1());
    const [developmentQuery, setDevelopmentQuery] = useState([]);
    const [developmentQueryId, setDevelopmentQueryId] = useState(uuidv1());
    const [needUpdate, setNeedUpdate] = useState(false);
    const [tables, setTables] = useState(DEFAULT_TABLE_VALUE);
    const [configExist] = useState(searchParams.get('config') !== null);

    const isOperator = (node) => node.type !== 'FILTER';

    const convertQueryToTree = useCallback((elt, tree) => {
        if (!elt) {
            console.info('Fail to convert query to tree: Empty element:', elt, tree);
            return;
        }
        let negate = false;
        while (isOperator(elt) && elt['name'] === 'NOT') {
            negate = !negate;
            if (!elt['body']) {
                console.warn('Empty NOT operator');
                return;
            }
            elt = elt['body'];
        }
        const id = uuidv1();

        let children = elt['body'];
        if (isOperator(elt)) {
            children = elt['body'].map((child) => convertQueryToTree(child, tree));
        }

        tree.push({
            id: id,
            type: elt['type'],
            name: elt['name'],
            body: children,
            negate: negate,
            validated: true,
        });
        return id;
    }, []);

    const handleValidation = useCallback((found = false) => {
        if (validationTimeoutRef.current) {
            clearTimeout(validationTimeoutRef.current);
            validationTimeoutRef.current = null;
        }

        validationTimeoutRef.current = setTimeout(() => {
            try {
                if (validationRetry.current >= VALIDATION_MAX_RETRY) {
                    console.log('Validation process reached max retry.');
                    clearTimeout(validationTimeoutRef.current);
                    validationTimeoutRef.current = null;
                    return;
                }

                const validationButtons = document.querySelectorAll(VALIDATION_BUTTONS);

                if (!validationButtons || validationButtons.length === 0) {
                    if (found) {
                        console.log('Validation process finished.');
                        clearTimeout(validationTimeoutRef.current);
                        validationTimeoutRef.current = null;
                        return;
                    }

                    console.log('Failed to find validation buttons, retrying in 1s.');
                    validationRetry.current += 1;
                    handleValidation();
                    return;
                }

                validationButtons.forEach((button) => {
                    button.click();
                });

                console.log('Queries validated.');
                handleValidation(true);
            } catch (error) {
                console.warn('Failed to validate queries.');
                console.warn(error);
            }
        }, VALIDATE_BUTTONS_TIMEOUT);
    }, []);

    const loadConfig = useCallback(
        (configJson) => {
            if (!configJson || Object.keys(configJson).length === 0 || !configJson.tables) return;

            console.log('Loading config');

            setProjectName(configJson.projectName);

            try {
                const projectQuery = [];
                setProjectQueryId(convertQueryToTree(configJson.projectQuery, projectQuery));
                setProjectQuery(projectQuery);
            } catch (error) {
                console.warn('Failed to parse projectQuery from URL. Resetting project panel.');
                console.warn(error);
            }

            try {
                const developmentQuery = [];
                setDevelopmentQueryId(convertQueryToTree(configJson.developmentQuery, developmentQuery));
                setDevelopmentQuery(developmentQuery);
            } catch (error) {
                console.warn('Failed to parse developmentQuery from URL. Resetting project panel.');
                console.warn(error);
            }
            try {
                const parsedTables = configJson.tables.map((table) => {
                    return {
                        ...table,
                        data: table.data.map((subject) => {
                            if (subject.subcategories && subject.subcategories.length > 0) {
                                return {
                                    ...subject,
                                    subcategories: subject.subcategories.map((subcategory) => {
                                        const subcategoryQuery = [];
                                        const id = convertQueryToTree(subcategory.query, subcategoryQuery);
                                        return {
                                            ...subcategory,
                                            query: subcategoryQuery,
                                            id: id,
                                        };
                                    }),
                                };
                            } else {
                                const subjectQuery = [];
                                const id = convertQueryToTree(subject.query, subjectQuery);
                                return {
                                    ...subject,
                                    query: subjectQuery,
                                    id: id,
                                };
                            }
                        }),
                    };
                });
                setTables(parsedTables);
                validationRetry.current = 0;
                handleValidation();
            } catch (error) {
                console.warn('Failed to parse tables from URL. Resetting project panel.');
                console.warn(error);
            }
        },
        [convertQueryToTree, handleValidation],
    );

    useEffect(() => {
        return () => {
            if (validationTimeoutRef.current) {
                clearTimeout(validationTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!config || !editMode) return;

        if (configExist) return;
        try {
            loadConfig(config);
        } catch (error) {
            console.warn('Failed to parse config from the model. Resetting project panel.');
            console.warn(error);
        }
    }, [config, editMode, loadConfig, configExist]);

    useEffect(() => {
        try {
            const existingConfig = searchParams.get('config');
            if (existingConfig) {
                const decodedConfig = atob(existingConfig);
                const configJson = JSON.parse(decodedConfig);
                loadConfig(configJson);
            }
        } catch (error) {
            console.warn('Failed to parse config from URL. Resetting project panel.');
            console.warn(error);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!needUpdate) setNeedUpdate(true);
    }, [projectName, projectQuery, developmentQuery, tables, setSearchParams, projectQueryId, developmentQueryId]); // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {
        if (!needUpdate) return;

        try {
            const projectData = {
                projectName,
                projectQuery: denormalizeTree(projectQueryId, projectQuery),
                developmentQuery: denormalizeTree(developmentQueryId, developmentQuery),
                tables: denormalizeTable(tables),
            };

            const projectDataString = JSON.stringify(projectData);
            const encodedData = btoa(projectDataString);
            setSearchParams({ config: encodedData }, { replace: true });
            console.log('Saved in the url');
        } catch (error) {
            console.warn('Failed to update URL with project data');
            console.warn(error);
        }
        setNeedUpdate(false);
    }, URL_SAVE_INTERVAL);

    useEffect(() => {
        const checkButtonDisable = () => {
            const validationButtons = document.querySelectorAll(VALIDATION_BUTTONS);
            let isVisible = false;
            validationButtons.forEach((button) => {
                if (button.offsetParent !== null) {
                    isVisible = true;
                }
            });
            setAreQueryValidated(!isVisible);
        };

        checkButtonDisable();
        const observer = new MutationObserver(checkButtonDisable);

        observer.observe(document.body, { attributes: true, childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    const handleTableNameChange = (index, e) => {
        const updatedTables = [...tables];
        updatedTables[index].title = e.target.value;
        setTables(updatedTables);
    };

    const handleDeleteTable = (index) => {
        setTables(tables.filter((_, i) => i !== index));
    };

    const handleSubjectChange = (tableIndex, subjectIndex, value, subIndex = undefined) => {
        const updatedTables = [...tables];
        if (subIndex !== undefined)
            updatedTables[tableIndex].data[subjectIndex].subcategories[subIndex].subject = value;
        else updatedTables[tableIndex].data[subjectIndex].subject = value;
        setTables(updatedTables);
    };

    const handleQueryChange = (tableIndex, subjectIndex, queryIndex, nodes) => {
        const updatedTables = [...tables];

        if (typeof nodes === 'function') {
            const currentQuery =
                queryIndex !== undefined
                    ? updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query
                    : updatedTables[tableIndex].data[subjectIndex].query;

            const updatedNodes = nodes(currentQuery);

            if (queryIndex !== undefined) {
                updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query = updatedNodes;
            } else {
                updatedTables[tableIndex].data[subjectIndex].query = updatedNodes;
            }
        } else {
            if (queryIndex !== undefined) {
                updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query = nodes;
            } else {
                updatedTables[tableIndex].data[subjectIndex].query = nodes;
            }
        }

        setTables(updatedTables);
    };

    const handleAddSubcategory = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        if (!updatedTables[tableIndex].data[subjectIndex].subcategories) {
            updatedTables[tableIndex].data[subjectIndex].subcategories = [];
        }
        if (updatedTables[tableIndex].data[subjectIndex].query) {
            delete updatedTables[tableIndex].data[subjectIndex].query;
        }
        updatedTables[tableIndex].data[subjectIndex].subcategories.push({ subject: '', query: [] });
        setTables(updatedTables);
    };

    const handleRemoveSubcategory = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        const subcategories = updatedTables[tableIndex].data[subjectIndex].subcategories;
        if (subcategories && subcategories.length > 0) {
            subcategories.pop();
            if (subcategories.length === 0) {
                delete updatedTables[tableIndex].data[subjectIndex].subcategories;
                updatedTables[tableIndex].data[subjectIndex].query = [];
            }
            setTables(updatedTables);
        }
    };

    const getAndSetSubcategoryId = (tableIndex, subjectIndex, subIndex) => {
        let currentId = undefined;

        if (subIndex !== undefined) currentId = tables[tableIndex].data[subjectIndex].subcategories[subIndex]?.id;
        else currentId = tables[tableIndex].data[subjectIndex]?.id;

        if (currentId) return currentId;

        const updatedTables = [...tables];
        currentId = uuidv1();
        if (subIndex !== undefined) updatedTables[tableIndex].data[subjectIndex].subcategories[subIndex].id = currentId;
        else updatedTables[tableIndex].data[subjectIndex].id = currentId;

        setTables(updatedTables);
        return currentId;
    };

    const handleAddTable = () => {
        setTables([...tables, { title: 'New Table', data: [{ subject: '', query: [] }] }]);
    };

    const handleAddRow = (tableIndex) => {
        const updatedTables = [...tables];
        updatedTables[tableIndex].data.push({ subject: '', query: [] });
        setTables(updatedTables);
    };

    const handleDeleteRow = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        updatedTables[tableIndex].data = updatedTables[tableIndex].data.filter((_, index) => index !== subjectIndex);
        setTables(updatedTables);
    };

    const denormalizeTable = (tables) => {
        const modifiedTables = JSON.parse(JSON.stringify(tables));
        for (let table of modifiedTables) {
            for (let subject of table.data) {
                if (subject.subcategories && subject.subcategories.length > 0) {
                    for (let subcategory of subject.subcategories) {
                        subcategory.query = denormalizeTree(subcategory.id, subcategory.query);
                        subcategory.id = undefined;
                    }
                } else {
                    subject.query = denormalizeTree(subject.id, subject.query);
                }
                subject.id = undefined;
            }
        }

        return modifiedTables;
    };

    const handleSubmit = () => {
        submitProject(
            projectName,
            denormalizeTree(projectQueryId, projectQuery),
            denormalizeTree(developmentQueryId, developmentQuery),
            denormalizeTable(tables),
        );
    };
    const allTableQuerySet = () => {
        for (let table of tables) {
            for (let subject of table.data) {
                if (subject.subcategories && subject.subcategories.length > 0) {
                    for (let subcategory of subject.subcategories) {
                        if (
                            !subcategory.query ||
                            subcategory.query.length === 0 ||
                            !subcategory.query.some((q) => q.name)
                        ) {
                            return false;
                        }
                    }
                } else if (!subject.query || subject.query.length === 0 || !subject.query.some((q) => q.name)) {
                    return false;
                }
            }
        }
        return true;
    };

    const isSaveButtonEnable = () => {
        return (
            allTableQuerySet() &&
            projectName &&
            projectQuery.length &&
            projectQuery[0]?.name &&
            developmentQuery.length &&
            developmentQuery[0]?.name &&
            areQueryValidated
        );
    };

    return (
        <Paper sx={styles.paper}>
            <EnhancedTableToolbar
                isLoading={false}
                title={editMode ? 'Edit Project' : 'Create Project'}
                backUri={getPanelUri(<ProjectPanel />)}
                backText='Back to Projects'
                margin={'no-m'}
            />
            <Box sx={styles.section}>
                <Box sx={styles.center}>
                    <Typography variant='h5' sx={styles.sectionTitle}>
                        Specs
                    </Typography>
                </Box>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Grid container spacing={3} justifyContent={'center'} direction={'column'}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.name}>
                                <TextField
                                    label='Project Name'
                                    variant='outlined'
                                    fullWidth
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} direction={'column'}>
                    <hr style={styles.separator} />
                    <Grid item sx={styles.queryItem}>
                        <Box sx={styles.center}>
                            <Typography variant='h6'>Project Query</Typography>
                        </Box>
                        <LocalSegmentQueryBuilder
                            setNodes={setProjectQuery}
                            nodes={projectQuery}
                            id={projectQueryId}
                            noDevSyntax
                        />
                    </Grid>
                    <hr style={styles.separator} />
                    <Grid item sx={styles.queryItem}>
                        <Box sx={styles.center}>
                            <Typography variant='h6'>Development Query</Typography>
                        </Box>
                        <LocalSegmentQueryBuilder
                            setNodes={setDevelopmentQuery}
                            nodes={developmentQuery}
                            id={developmentQueryId}
                            noDevSyntax
                        />
                    </Grid>
                </Grid>
            </Box>

            {tables.map((table, tableIndex) => (
                <Box key={tableIndex} sx={styles.section}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} sm={6} md={4} lg={3}>
                            <Grid container spacing={3} justifyContent={'center'} direction={'column'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.name}>
                                    <TextField
                                        label='Table Title'
                                        variant='outlined'
                                        fullWidth
                                        value={table.title}
                                        onChange={(e) => handleTableNameChange(tableIndex, e)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            {tables.length === 1 ? (
                                <IconButton disabled={true} sx={{ margin: theme.spacing(1) }}>
                                    <DeleteIcon color='disabled' />
                                </IconButton>
                            ) : (
                                <TooltipMui title='Delete Table'>
                                    <IconButton
                                        onClick={() => handleDeleteTable(tableIndex)}
                                        sx={{ margin: theme.spacing(1) }}
                                    >
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </TooltipMui>
                            )}
                        </Grid>
                    </Grid>
                    <TableContainer sx={{ marginTop: theme.spacing(3) }}>
                        <Table sx={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={styles.tableCell}>Subject</TableCell>
                                    <TableCell sx={styles.tableCell}>Matching Query</TableCell>
                                    <TableCell sx={styles.tableCell}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table.data.map((subject, subjectIndex) => (
                                    <TableRow key={subjectIndex}>
                                        <TableCell sx={styles.tableCell}>
                                            <TextField
                                                sx={styles.textfiled}
                                                value={subject.subject}
                                                onChange={(e) =>
                                                    handleSubjectChange(tableIndex, subjectIndex, e.target.value)
                                                }
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            {subject.subcategories &&
                                                subject.subcategories.map((subcategory, subIndex) => (
                                                    <Box key={subIndex}>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    sx={styles.textfiled}
                                                                    value={subcategory.subject}
                                                                    onChange={(e) =>
                                                                        handleSubjectChange(
                                                                            tableIndex,
                                                                            subjectIndex,
                                                                            e.target.value,
                                                                            subIndex,
                                                                        )
                                                                    }
                                                                    variant='outlined'
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <LocalSegmentQueryBuilder
                                                                    key={subIndex}
                                                                    setNodes={(nodes) =>
                                                                        handleQueryChange(
                                                                            tableIndex,
                                                                            subjectIndex,
                                                                            subIndex,
                                                                            nodes,
                                                                        )
                                                                    }
                                                                    nodes={subcategory.query}
                                                                    noDevSyntax
                                                                    id={getAndSetSubcategoryId(
                                                                        tableIndex,
                                                                        subjectIndex,
                                                                        subIndex,
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            {!subject.subcategories && (
                                                <Box>
                                                    <LocalSegmentQueryBuilder
                                                        setNodes={(nodes) =>
                                                            handleQueryChange(
                                                                tableIndex,
                                                                subjectIndex,
                                                                undefined,
                                                                nodes,
                                                            )
                                                        }
                                                        nodes={subject.query}
                                                        noDevSyntax
                                                        id={getAndSetSubcategoryId(tableIndex, subjectIndex)}
                                                    />
                                                </Box>
                                            )}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {subject.subcategories && subject.subcategories.length > 0 && (
                                                    <TooltipMui title='Remove Last Subcategory'>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveSubcategory(tableIndex, subjectIndex)
                                                            }
                                                        >
                                                            <RemoveIcon color='secondary' />
                                                        </IconButton>
                                                    </TooltipMui>
                                                )}
                                                <TooltipMui title='Add Subcategory'>
                                                    <IconButton
                                                        onClick={() => handleAddSubcategory(tableIndex, subjectIndex)}
                                                    >
                                                        <AddIcon color='primary' />
                                                    </IconButton>
                                                </TooltipMui>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            <TooltipMui title='Delete Row'>
                                                <IconButton onClick={() => handleDeleteRow(tableIndex, subjectIndex)}>
                                                    <DeleteIcon color='error' />
                                                </IconButton>
                                            </TooltipMui>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                                        <TooltipMui title='Add Row'>
                                            <IconButton onClick={() => handleAddRow(tableIndex)}>
                                                <AddIcon color='primary' />
                                            </IconButton>
                                        </TooltipMui>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}

            <Box sx={styles.buttonContainer}>
                <TooltipMui title='Add Table'>
                    <IconButton onClick={handleAddTable}>
                        <AddIcon color='primary' />
                    </IconButton>
                </TooltipMui>
            </Box>
            <Box sx={{ ...styles.buttonContainer, display: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                {!areQueryValidated && (
                    <Typography variant='body2' color='error'>
                        Please validate all queries before saving.
                    </Typography>
                )}
                {!projectName && (
                    <Typography variant='body2' color='error'>
                        Please enter a project name.
                    </Typography>
                )}
                {(!projectQuery.length || !projectQuery[0]?.name) && (
                    <Typography variant='body2' color='error'>
                        Please enter a project query.
                    </Typography>
                )}
                {(!developmentQuery.length || !developmentQuery[0]?.name) && (
                    <Typography variant='body2' color='error'>
                        Please enter a development query.
                    </Typography>
                )}
                {!allTableQuerySet() && (
                    <Typography variant='body2' color='error'>
                        Please enter a query for each element of each table row.
                    </Typography>
                )}
            </Box>

            <Box sx={styles.buttonContainer}>
                <Button variant='contained' color='primary' onClick={handleSubmit} disabled={!isSaveButtonEnable()}>
                    {editMode ? 'Save' : 'Create'}
                </Button>
            </Box>
        </Paper>
    );
};
export default ProjectForm;
