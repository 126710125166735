import { useTheme } from '@emotion/react';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

function ProjectCard({ project }) {
    const theme = useTheme();

    const styles = {
        card: {
            backgroundColor: theme.palette.common.verylightgray,
            color: theme.palette.common.black,
        },
        cardContent: {
            paddingBottom: '14px !important',
            position: 'relative',
        },
        iconBox: {
            display: 'flex',
            alignItems: 'center',
            marginRight: 2,
        },
        subDatasets: {
            marginRight: '4px',
            fontSize: '1vw',
            [theme.breakpoints.down('lg')]: {
                fontSize: '12px',
            },
        },
        folderIcon: {
            fontSize: 20,
            marginBottom: '4px',
        },
        projectName: {
            textAlign: 'left',
            maxWidth: '100%',
            fontSize: '1vw',
            [theme.breakpoints.down('lg')]: {
                fontSize: '12px',
            },
            fontWeight: 'bold',
        },
        lastUpdate: {
            position: 'absolute',
            right: 4,
            bottom: 0,
        },
        lastUpdateText: {
            fontSize: '10px',
        },
        gridItemMaxWidth: {
            maxWidth: 'calc(100% - 50px)',
        },
    };

    return (
        <Card sx={styles.card}>
            <CardContent sx={styles.cardContent}>
                <Grid container alignItems='center'>
                    <Grid item sx={{ maxWidth: '50px' }}>
                        <Box sx={styles.iconBox}>
                            <Typography component='div' sx={styles.subDatasets}>
                                {project?.sub_datasets?.length}
                            </Typography>
                            <FolderCopyIcon sx={styles.folderIcon} />
                        </Box>
                    </Grid>
                    <Grid item sx={styles.gridItemMaxWidth}>
                        <Typography component='div' sx={styles.projectName}>
                            {project.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={styles.lastUpdate}>
                    <Typography variant='caption' sx={styles.lastUpdateText}>
                        Last update: {new Date(project.updated_at).toLocaleString()}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

export default ProjectCard;
