import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import ReactECharts from 'echarts-for-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import EnhancedTableToolbar from '../../../components/Table/EnhancedTableToolbar';
import { validateBase64Dict, validateBase64List } from '../utils/BasicValidation';

const URL_PARAM_NAMES = {
    selectedNodes: 'selectedNodes',
    paginatedNodes: 'paginatedNodes',
};

const ITEM_PER_PAGE = 15;

const StatsTree = (props) => {
    const { labelTree, labelTreeFetch, latestDate, disableFrame } = props;
    const [localLabelTree, setLocalLabelTree] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedPath, setSelectedPath] = useState(
        validateBase64List(searchParams.get(URL_PARAM_NAMES.selectedNodes), ['.']) || [],
    );
    const [treePagination, setTreePagination] = useState(
        validateBase64Dict(searchParams.get(URL_PARAM_NAMES.paginatedNodes), {}) || {},
    );
    const intervalRef = useRef();
    const [isLoading, setIsLoading] = useState(true);

    const theme = useTheme();
    const styles = {
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            minWidth: '600px',
            marginBottom: '4px',
        },
        progress: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
        },
    };

    const updateTreeWithSelectedPaths = (node, paths) => {
        if (!node) return null;

        const updatedChildren = node.children?.map((child) => updateTreeWithSelectedPaths(child, paths)) || [];

        return {
            ...node,
            collapsed: !paths.includes(node.path),
            children: updatedChildren,
        };
    };

    const updateTreeWithPagination = (node, pagination) => {
        if (!node) return null;

        // Check if the node is a leaf
        if (!node.children) {
            return { ...node };
        }

        const start = (pagination[node.path] ?? 0) * ITEM_PER_PAGE;
        const end = start + ITEM_PER_PAGE;
        const children = node.children.slice(start, end);

        // Add the "prev" nodes
        if (start > 0) {
            const prevValues = node.children.slice(0, start).reduce((acc, child) => {
                return {
                    video: acc.video + child.video,
                    track: acc.track + child.track,
                    frame: acc.frame + child.frame,
                };
            });
            children.unshift({
                name: `${start} prev...`,
                path: `${node.path}.* (page ${pagination[node.path]})`,
                isPaginationNode: true,
                action: 'prev',
                parentPath: node.path,
                ...prevValues,
            });
        }

        // Add the "next" nodes
        if (node.children.length > end) {
            const nextValues = node.children.slice(end).reduce((acc, child) => {
                return {
                    video: acc.video + child.video,
                    track: acc.track + child.track,
                    frame: acc.frame + child.frame,
                };
            });

            children.push({
                name: `${node.children.length - end} next...`,
                path: `${node.path}.* (page ${pagination[node.path] + 2})`,
                isPaginationNode: true,
                action: 'next',
                parentPath: node.path,
                ...nextValues,
            });
        }

        return {
            ...node,
            children: children.map((child) => updateTreeWithPagination(child, pagination)),
        };
    };

    useEffect(() => {
        setIsLoading(true);
        labelTreeFetch();
    }, [labelTreeFetch]);

    useEffect(() => {
        if (!labelTree) {
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    console.log('Retrying label tree loading...');
                    labelTreeFetch();
                }, 10000);
            }
            return;
        }

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        setIsLoading(false);
        const updatedTree = updateTreeWithSelectedPaths(labelTree, selectedPath);
        const updatedPagination = updateTreeWithPagination(updatedTree, treePagination);
        setLocalLabelTree(updatedPagination);
    }, [labelTree, theme, treePagination]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearTimeout(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams[URL_PARAM_NAMES.selectedNodes] = btoa(JSON.stringify(selectedPath));
        newParams[URL_PARAM_NAMES.paginatedNodes] = btoa(JSON.stringify(treePagination));
        setSearchParams(newParams, { replace: true });
    }, [selectedPath, setSearchParams, treePagination]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClick = (params) => {
        if (params.data.isPaginationNode) {
            const { action, parentPath } = params.data;
            setTreePagination((prev) => {
                const currentPage = prev[parentPath] ?? 0;
                const newPage = action === 'next' ? currentPage + 1 : Math.max(currentPage - 1, 0);
                return {
                    ...prev,
                    [parentPath]: newPage,
                };
            });
            return;
        }

        if (!params.data.children) return;

        const currentPath = params.data.path;
        setSelectedPath((prev) => {
            if (prev.includes(currentPath)) {
                return prev.filter((path) => path !== currentPath);
            }
            return [...prev, currentPath];
        });
    };

    const options = useMemo(() => {
        return {
            tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
                backgroundColor: theme.palette.common.paper,
                textStyle: {
                    color: theme.palette.common.black,
                },
                formatter(params) {
                    if (params.data.path === '.') {
                        return null;
                    }

                    return `<div style="width: 100%; text-align: center; font-style: italic; color: ${
                        theme.palette.primary.main
                    }">${params.data.path}</div>
                <div>${params.marker} Videos: <span style="font-weight: bold">${
                        params.data.video?.toLocaleString('en-GB') || 0
                    }</span></div>
                <div>${params.marker} Tracks: <span style="font-weight: bold">${
                        params.data.track?.toLocaleString('en-GB') || 0
                    }</span></div>

                ${
                    disableFrame
                        ? ''
                        : `<div>${params.marker} Frames: <span style="font-weight: bold">${
                              params.data.frame?.toLocaleString('en-GB') || 0
                          }</span></div>`
                }`;
                },
            },
            backgroundColor: theme.palette.common.paper,
            textStyle: {
                fontSize: 14,
            },
            notMerge: false,
            series: [
                {
                    type: 'tree',
                    name: 'Labels',
                    symbol: 'emptyCircle',

                    data: [localLabelTree],
                    top: '2%',
                    left: '15%',
                    bottom: '2%',
                    right: '25%',
                    symbolSize: 14,
                    itemStyle: {
                        borderWidth: 0,
                        color: theme.palette.primary.main,
                    },
                    lineStyle: {
                        color: theme.palette.primary.main,
                    },
                    label: {
                        position: 'left',
                        verticalAlign: 'middle',
                        align: 'right',
                    },
                    leaves: {
                        label: {
                            position: 'right',
                            verticalAlign: 'middle',
                            align: 'left',
                        },
                    },
                    select: {
                        itemStyle: {
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.main,
                        },
                    },

                    initialTreeDepth: 0,
                    emphasis: {
                        focus: 'descendant',
                    },

                    expandAndCollapse: true,
                    animationDuration: 550,
                    animationDurationUpdate: 750,
                },
            ],
        };
    }, [localLabelTree, theme, disableFrame]);

    const memoChart = useMemo(
        () => (
            <ReactECharts
                style={{ height: '70vh', width: '100%' }}
                option={options}
                theme={theme.palette.mode}
                lazyUpdate
                onEvents={{ click: onClick }}
            />
        ),
        [options, theme],
    );

    if (!localLabelTree || !localLabelTree.children) {
        return (
            <Paper sx={styles.paper}>
                <Box sx={styles.progress}>
                    <CircularProgress data-testid='graph-progress' />
                </Box>
            </Paper>
        );
    }

    return (
        <Paper sx={styles.paper}>
            <EnhancedTableToolbar title={disableFrame ? 'Segment Structure' : 'Label Structure'} />
            {isLoading ? (
                <Box sx={styles.loading}>
                    <CircularProgress />
                </Box>
            ) : (
                memoChart
            )}
            <Typography variant='body2' color='textSecondary' sx={{ position: 'absolute', left: 8, bottom: 2 }}>
                {latestDate}
            </Typography>
        </Paper>
    );
};

export default StatsTree;
