import { ButtonGroup, IconButton, Tooltip as TooltipMui } from '@mui/material';
import React from 'react';

import { AVAILABLE_FILTER_MAP, SEGMENT_FILTERING, getFormattedName } from '../utils';

const AttributeStatsFilterControls = ({ filter, handleFilter, segmentMode }) => (
    <ButtonGroup color='primary' aria-label='outlined primary button group'>
        {Object.keys(AVAILABLE_FILTER_MAP)
            .filter((order) => !segmentMode || SEGMENT_FILTERING.includes(order))
            .map((order) => (
                <TooltipMui title={getFormattedName(order)} key={order}>
                    <IconButton onClick={() => handleFilter(order)} color={filter === order ? 'primary' : 'default'}>
                        {AVAILABLE_FILTER_MAP[order]}
                    </IconButton>
                </TooltipMui>
            ))}
    </ButtonGroup>
);

export default AttributeStatsFilterControls;
