import { CssBaseline } from '@mui/material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { actions } from '../actions';
import Login from '../components/LoginForm';
import { useInterval } from '../services/helpers';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import { hiddenPanels, panels } from './panels';
import { getPanelUri } from './panels';
import HomePanel from './panels/Home';

const REFRESH_PERMS_INTERVAL = 1000 * 60 * 10; // 10 minutes

function DashboardDrawer(props) {
    const theme = useTheme();
    const { isUserLoggedIn, authRefresh, requestDoc } = props;

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (isUserLoggedIn) authRefresh();
    }, [isUserLoggedIn, authRefresh]);

    useInterval(() => {
        if (isUserLoggedIn) authRefresh();
    }, REFRESH_PERMS_INTERVAL);

    useEffect(() => {
        if (isUserLoggedIn) requestDoc();
    }, [requestDoc, isUserLoggedIn]);

    return (
        <Box sx={{ display: 'flex', overflowX: 'hidden' }}>
            <CssBaseline />
            {!isUserLoggedIn ? (
                <Login />
            ) : (
                <Router>
                    <NavBar isOpen={open} onToggle={() => setOpen(!open)} />
                    <SideBar isOpen={open} onToggle={() => setOpen(!open)} authCancel={props.authCancel} />
                    <Box sx={{ flexGrow: 1, padding: theme.spacing(3), overflow: 'auto', paddingBottom: 0 }}>
                        <main style={{ flexGrow: 1, padding: theme.spacing(3), paddingBottom: 0 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    padding: theme.spacing(0, 1),
                                    ...theme.mixins.toolbar,
                                }}
                            />
                            <Routes>
                                {panels.map((panel, index) => (
                                    <Route key={index} path={panel.uri} element={panel.component} />
                                ))}
                                {hiddenPanels.map((panel, index) => (
                                    <Route key={`hidden-${index}`} path={panel.uri} element={panel.component} />
                                ))}
                                <Route path='*' element={<Navigate to={getPanelUri(<HomePanel />)} />} />
                            </Routes>
                        </main>
                    </Box>
                </Router>
            )}
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        isUserLoggedIn: state.auth.loggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authCancel: () => dispatch(actions.api.auth.fetch.cancel()),
        authRefresh: () => dispatch(actions.api.auth.refresh.request()),
        requestDoc: () => dispatch(actions.api.qbuilder.doc.request()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDrawer);
