import { Card, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { PROJECT_COLORS, PROJECT_ENVIRONMENTS } from '../../Projects/utils';

const ProjectTable = ({ title, data }) => {
    const theme = useTheme();

    const styles = {
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        tableHeader: {
            fontWeight: 'bold',
            textAlign: 'left',
            padding: theme.spacing(1),
        },
        tableRow: {
            textAlign: 'left',
            padding: theme.spacing(1),
            fontWeight: 'bold',
        },
        border: {
            borderBottom: `1px solid ${theme.palette.common.lightgray}`,
        },
        container: {
            margin: `${theme.spacing(3)} 0 0 0`,
            backgroundColor: theme.palette.common.verylightgray,
            paddingTop: theme.spacing(2),
            borderRadius: '5px',
        },
        subRow: {
            display: 'inline',
            marginLeft: theme.spacing(2),
        },
        subRowValue: {
            borderBottom: 'none',
            fontWeight: 'normal',
            fontSize: '0.8rem',
        },
        noBorder: {
            borderBottom: 'none',
        },
    };

    const calculateSum = (subcategories, env) => {
        return subcategories.reduce((acc, sub) => {
            const value = sub[env];
            return acc + (Number.isFinite(value) ? value : 0);
        }, 0);
    };

    return (
        <Card sx={styles.container}>
            <Typography variant='h6' gutterBottom sx={{ textAlign: 'center' }}>
                {title}
            </Typography>
            <table style={styles.table}>
                <thead>
                    <tr style={styles.border}>
                        <th style={styles.tableHeader}></th>
                        {PROJECT_ENVIRONMENTS.map((env, index) => (
                            <th
                                key={env}
                                style={{
                                    ...styles.tableHeader,
                                    width: `300px`,
                                    color: PROJECT_COLORS[index],
                                    textAlign: 'center',
                                }}
                            >
                                {env}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((elt, index) => (
                        <React.Fragment key={index}>
                            <tr
                                style={{
                                    ...styles.tableRow,
                                    ...(index !== data.length - 1 && styles.border),
                                }}
                            >
                                <td style={styles.tableRow}>
                                    {elt.subject}
                                    {elt.subcategories && elt.subcategories.length > 0 && (
                                        <span style={styles.subRow}>
                                            {elt.subcategories.map((sub, subIndex) => (
                                                <span key={subIndex} style={styles.subRowValue}>
                                                    <br />
                                                    &nbsp;&nbsp;&nbsp;{sub.subject}
                                                </span>
                                            ))}
                                        </span>
                                    )}
                                </td>
                                {PROJECT_ENVIRONMENTS.map((env, index) => (
                                    <td
                                        key={env}
                                        style={{
                                            ...styles.tableRow,
                                            width: `300px`,
                                            color: PROJECT_COLORS[index],
                                            textAlign: 'center',
                                        }}
                                    >
                                        {elt.subcategories ? calculateSum(elt.subcategories, env) : elt[env]}
                                        {elt.subcategories &&
                                            elt.subcategories.map((sub, subIndex) => (
                                                <span key={subIndex} style={styles.subRowValue}>
                                                    <br />
                                                    {sub[env]}
                                                </span>
                                            ))}
                                    </td>
                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </Card>
    );
};

export default ProjectTable;
