import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LNumberRange(props) {
    const { onChange, defaultValue, nodeId, setValidation, disableValidation } = props;

    const [updated, setUpdated] = useState(true);
    const [minValue, setMinValue] = useState('0');
    const [maxValue, setMaxValue] = useState('0');

    const [isErrorMinValue, setIsErrorMinValue] = useState(false);
    const [isErrorMaxValue, setIsErrorMaxValue] = useState(false);

    const handleClickValidation = () => {
        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([minValue, maxValue]);
    };

    useEffect(() => {
        if (!Array.isArray(defaultValue) || defaultValue.length !== 2) return;
        const [min, max] = defaultValue;
        setMinValue(min);
        setMaxValue(max);
    }, [defaultValue, setMinValue, setMaxValue]);

    useEffect(() => {
        setIsErrorMinValue(minValue > maxValue);
    }, [minValue, maxValue, setIsErrorMinValue]);

    useEffect(() => {
        setIsErrorMaxValue(minValue > maxValue);
    }, [minValue, maxValue, setIsErrorMaxValue]);

    const handleValidatation = () => {
        return isErrorMinValue || isErrorMaxValue || maxValue === null || minValue === null;
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <TextField
                    variant='outlined'
                    label='Minimum'
                    type='number'
                    error={isErrorMinValue}
                    onChange={(event) => {
                        setMinValue(event.target.value.includes('.') ? event.target.value : event.target.value + '.0');
                        setUpdated(true);
                        if (!disableValidation) setValidation({ nodeId, validate: false });
                    }}
                />
            </Grid>
            <Grid item>
                <TextField
                    variant='outlined'
                    label='Maximum'
                    type='number'
                    error={isErrorMaxValue}
                    onChange={(event) => {
                        setMaxValue(event.target.value.includes('.') ? event.target.value : event.target.value + '.0');
                        setUpdated(true);
                        if (!disableValidation) setValidation({ nodeId, validate: false });
                    }}
                />
            </Grid>
            <Grid item>
                <ValidationButton
                    error={isErrorMinValue || isErrorMaxValue}
                    onClick={handleClickValidation}
                    updated={updated}
                    disabled={handleValidatation}
                />
            </Grid>
        </BodyBuilderGrid>
    );
}

LNumberRange.propTypes = {
    onChange: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
    };
};

export default connect(null, mapDispatchToProps)(LNumberRange);
