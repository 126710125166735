// import { hot } from 'react-hot-loader/root';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actions } from './actions';
import Notifier from './components/Notifier';
import { darkScrollbarStyles, lightScrollbarStyles } from './components/ScrollBar/ScrollBar';
import Home from './dashboard';
import { themeDark, themeLight } from './services';
import { api } from './services/api';

if (process.env.REACT_APP_ENV === 'docker') {
    axios.defaults.baseURL = `${process.env.REACT_APP_DJANGO_URL}`;
} else {
    axios.defaults.baseURL = window.location.origin;
}

function App(props) {
    const { lightOn, isUserLoggedIn, checkBackendHealth, checkAuth, dataCheckBackendHealth } = props;

    const scrollbarStyles = lightOn ? lightScrollbarStyles : darkScrollbarStyles;

    useEffect(() => {
        checkBackendHealth();
        dataCheckBackendHealth();
    }, [checkBackendHealth, dataCheckBackendHealth]);

    useEffect(() => {
        checkAuth(api.auth.validate);
        // if (checkAuth(api.auth.validate) !== 200)
        //     logout();
    }, [isUserLoggedIn, checkAuth]);

    return (
        <ThemeProvider theme={lightOn ? themeLight : themeDark}>
            <GlobalStyles styles={scrollbarStyles} />

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider>
                    <Notifier />
                    <Home />
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        isUserLoggedIn: state.auth.loggedIn,
        lightOn: state.preference.lightOn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkAuth: (endpoint) => dispatch(actions.api.auth.validate.request({ endpoint })),
        checkBackendHealth: () => dispatch(actions.api.health.request()),
        dataCheckBackendHealth: () => dispatch(actions.api.data_health.request()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
