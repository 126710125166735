import { useTheme } from '@emotion/react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, CircularProgress, IconButton, Paper, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

import { actions } from '../../../actions';
import { api } from '../../../services';

function AnnotationDetails(props) {
    const theme = useTheme();
    const { hash } = useParams();
    const { annotationData, fetchAnnotationData, setAnnotationDetails, isLoading, resetAnnotationDetails, catUrl } =
        props;

    const styles = {
        header: {
            display: 'flex',
            flexDirection: 'column',
        },
        hash: {
            p: 0.5,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            marginBottom: '4px',
        },
        title: {
            fontWeight: 'bold',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
        },
        body: {
            padding: theme.spacing(2),
        },
        centerError: {
            textAlign: 'center',
            color: theme.palette.error.main,
            padding: theme.spacing(2),
        },
        progress: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
        },
        open: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    };

    const classes = styles;
    useEffect(() => {
        if (hash) {
            resetAnnotationDetails();
            setAnnotationDetails(hash);
            fetchAnnotationData(hash);
        }
    }, [hash, fetchAnnotationData, setAnnotationDetails, resetAnnotationDetails]);

    if (!hash || !hash.match(/^[a-z0-9]{128}$/i)) {
        const lengthError = hash && hash.length !== 128;
        const charError = hash && hash.match(/[^a-z0-9]/i);
        return (
            <Paper sx={classes.paper}>
                <Toolbar sx={classes.header}>
                    <Typography sx={classes.title} variant='h5'>
                        Annotation Details
                    </Typography>
                    <Typography sx={classes.hash}>{hash}</Typography>
                </Toolbar>
                <Box sx={classes.centerError}>
                    <Typography variant='body1'>Invalid hash.</Typography>
                    {lengthError && <Typography variant='body1'>The hash must be 128 characters long.</Typography>}
                    {charError && (
                        <Typography variant='body1'>The hash must contains only letters and numbers.</Typography>
                    )}
                </Box>
            </Paper>
        );
    }

    if (!annotationData || Object.keys(annotationData).length === 0) {
        if (isLoading) {
            return (
                <Paper sx={classes.paper}>
                    <Toolbar sx={classes.header}>
                        <Typography sx={classes.title} variant='h5'>
                            Annotation Details
                        </Typography>
                        <Typography sx={classes.hash}>{hash}</Typography>
                    </Toolbar>
                    <Box sx={styles.progress}>
                        <CircularProgress />
                    </Box>
                </Paper>
            );
        }

        return (
            <Paper sx={classes.paper}>
                <Toolbar sx={classes.header}>
                    <Typography sx={classes.title} variant='h5'>
                        Annotation Details
                    </Typography>
                    <Typography sx={classes.hash}>{hash}</Typography>
                </Toolbar>
                <Box sx={classes.centerError}>
                    <Typography variant='body1'>Annotation not found.</Typography>
                </Box>
            </Paper>
        );
    }

    return (
        <Paper sx={classes.paper}>
            <Toolbar sx={classes.header}>
                <Typography sx={classes.title} variant='h5'>
                    Annotation Details
                </Typography>
                <Typography sx={classes.hash}>{hash}</Typography>
            </Toolbar>
            <Box sx={classes.body}>
                <SyntaxHighlighter
                    language='json'
                    style={theme.material}
                    customStyle={{ maxHeight: 'calc(100vh - 320px)' }}
                >
                    {JSON.stringify(annotationData, null, 2)}
                </SyntaxHighlighter>
            </Box>
            <Tooltip title={'Open the task in CAT'} placement='left'>
                <IconButton
                    sx={classes.open}
                    href={catUrl}
                    target='_blank'
                    rel='noreferrer'
                    aria-label='open in cat'
                    disabled={!catUrl}
                >
                    <OpenInNewIcon />
                </IconButton>
            </Tooltip>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return {
        annotationData: (state.processedData[api.endpoints.annDetails] || {}).data?.data || {},
        catUrl: state.processedData[api.endpoints.annDetails]?.data?.cat_url || null,
        isLoading:
            state.processedData[api.endpoints.annDetails]?.isFetching ||
            state.processedData[api.endpoints.annDetails]?.isUpdating ||
            false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAnnotationData: (hash) =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annDetails,
                    hash: hash,
                }),
            ),
        setAnnotationDetails: (hash) => dispatch(actions.annotation.details.set(hash)),
        resetAnnotationDetails: () => dispatch(actions.api.processedData.reset({ endpoint: api.endpoints.annDetails })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationDetails);
