import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import LDate from './LDate';
import LDateRange from './LDateRange';
import LDynamicInput from './LDynamicInput';
import LNone from './LNone';
import LNumber from './LNumber';
import LNumberRange from './LNumberRange';
import LStr from './LStr';
import LStrSelect from './LStrSelect';

const handledFilterType = {
    L_STR: (onChange, defaultValue, id, local) => (
        <LStr defaultValue={defaultValue} onChange={onChange} nodeId={id} disableValidation={local} />
    ),
    L_STR_SELECT: (onChange, defaultValue, id, local, doc) => (
        <LStrSelect defaultValue={defaultValue} onChange={onChange} nodeId={id} doc={doc} disableValidation={local} />
    ),
    L_INT_RANGE: (onChange, defaultValue, id, local) => (
        <LNumberRange defaultValue={defaultValue} onChange={onChange} nodeId={id} disableValidation={local} />
    ),
    L_INT: (onChange, defaultValue, id, local) => (
        <LNumber defaultValue={defaultValue} onChange={onChange} nodeId={id} disableValidation={local} />
    ),
    L_FLOAT_RANGE: (onChange, defaultValue, id, local) => (
        <LNumberRange defaultValue={defaultValue} onChange={onChange} nodeId={id} disableValidation={local} />
    ),
    L_FLOAT: (onChange, defaultValue, id, local) => (
        <LNumber defaultValue={defaultValue} onChange={onChange} nodeId={id} disableValidation={local} />
    ),
    L_DATE: (onChange, defaultValue, id, local) => (
        <LDate defaultValue={defaultValue} onChange={onChange} nodeId={id} disableValidation={local} />
    ),
    L_DATE_RANGE: (onChange, defaultValue, id, local) => (
        <LDateRange defaultValue={defaultValue} onChange={onChange} nodeId={id} disableValidation={local} />
    ),
    L_NONE: (onChange, defaultValue, id, local) => <LNone onChange={onChange} nodeId={id} disableValidation={local} />,
    L_DYNAMIC_INPUT: (onChange, defaultValue, id, local, doc) => (
        <LDynamicInput
            defaultValue={defaultValue}
            onChange={onChange}
            nodeId={id}
            doc={doc}
            disableValidation={local}
        />
    ),
};

function FilterSelector(props) {
    const { onFilterChange, onDataChange, defaultFilter, defaultData, filterType, doc, setError, nodeId, local } =
        props;
    const filters = doc[filterType];

    const [selectedFilter, setSelectedFilter] = useState(defaultFilter);

    const handleFilterChange = (event, value, reason) => {
        setError(true);
        setSelectedFilter(value);
        onFilterChange(value);
    };

    const handleDataChange = (newData) => {
        onDataChange(newData);
    };

    const getFilterDoc = (name) => {
        return filters[name];
    };

    const getFilterType = (name) => {
        return filters[name]?.type || 'L_STR';
    };

    return (
        (filters && (
            <Grid container spacing={4} data-testid={'filter-selector'}>
                <Grid item>
                    <Autocomplete
                        autoComplete
                        autoHighlight
                        autoSelect
                        value={defaultFilter}
                        onChange={handleFilterChange}
                        options={Object.keys(filters)}
                        sx={{ width: 300 }}
                        getOptionDisabled={(option) => handledFilterType[getFilterType(option)] === undefined}
                        renderInput={(params) => {
                            return <TextField {...params} label='Filter layer' fullWidth />;
                        }}
                        data-testid={'filter-selector-autocomplete'}
                        // renderOption={(props, option) => {
                        //     <Tooltip title={getFilterDescription(option)}>
                        //         <Box width={1}>{option}</Box>
                        //     </Tooltip>
                        // }}
                    />
                </Grid>
                {selectedFilter && (
                    <Grid item key={selectedFilter}>
                        {handledFilterType[getFilterType(selectedFilter)](
                            handleDataChange,
                            defaultData,
                            nodeId,
                            local,
                            getFilterDoc(selectedFilter),
                        )}
                    </Grid>
                )}
            </Grid>
        )) || <Typography data-testid={'filter-empty-selector'}>Loading...</Typography>
    );
}

FilterSelector.propTypes = {
    filterType: PropTypes.string.isRequired,
    doc: PropTypes.object.isRequired,
    nodeId: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func,
    onDataChange: PropTypes.func,
    defaultFilter: PropTypes.string,
    defaultData: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        doc: state.qbuilder.doc,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setError: (err) => dispatch(actions.qbuilder.error(err)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelector);
