import React from 'react';
import { XAxis, YAxis } from 'recharts';

const generateDateTicks = (data, intervalDays) => {
    if (data.length === 0 || !data[0]?.date) {
        return [];
    }
    const startDate = new Date(data[0].date);
    const endDate = new Date(data[data.length - 1].date);
    const dateTicks = [];

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + intervalDays)) {
        dateTicks.push(new Date(date).getTime());
    }

    return dateTicks;
};

const ChartAxes = ({ theme, data }) => (
    <>
        <XAxis
            stroke={theme.palette.common.black}
            dataKey='database_axis_date_KEY'
            scale='time'
            type='number'
            domain={['dataMin', 'dataMax']}
            tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
            ticks={generateDateTicks(data, 3)}
        />
        <YAxis />
    </>
);

export default ChartAxes;
