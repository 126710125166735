import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const initialEndpointState = {
    data: [],

    errorOnFetch: null,
    errorOnUpdate: null,
    errorOnRemove: null,
    errorOnPost: null,

    isFetching: false,
    isUpdating: false,
    isRemoving: false,
    isPosting: false,

    isBusy: false,

    updatingIds: [],

    lastUpdate: null,
};

const initialState = {};

const reduceUpdateSuccess = (state, action) => {
    const dsToUpdate = new Set(action.payload.data.map((ds) => ds.id));
    const stateEndpointData = state[action.payload.endpoint]?.data ? state[action.payload.endpoint].data : [];

    return {
        ...state,
        [action.payload.endpoint]: {
            isUpdating: false,
            lastUpdate: Date.now(),
            data: [...stateEndpointData.filter((ds) => !dsToUpdate.has(ds.id)), ...action.payload.data],
            isBusy: false,
        },
    };
};

const reduceRemoveSuccess = (state, action) => {
    return {
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            data: state[action.payload.endpoint]?.data,
            isRemoving: false,
            isBusy: false,
        },
    };
};

export const data = createReducer(initialState, {
    // FIXME: we should probably throw an exception if the endpoint was already registered for tracking,
    //        instead of just ignoring the new request. Such a thing is more likely to be caused by a lake
    //        of logic in the app workflow. But maybe we should just ignore it as we're doing now? IDK :/
    // FIXME-END
    [actions.api.data.tracker.add]: (state, action) =>
        state[action.payload.endpoint] !== undefined
            ? state
            : {
                  ...state,
                  [action.payload.endpoint]: initialEndpointState,
              },

    [actions.api.data.tracker.remove]: (state, action) => {
        const { [action.payload.endpoint]: _, ...newState } = state;
        return newState;
    },

    [actions.api.data.tracker.clear]: (state, action) => initialState,

    [actions.api.data.fetch.request]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: { ...state[action.payload.endpoint], isFetching: true },
    }),

    [actions.api.data.fetch.failure]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isFetching: false,
            errorOnFetch: action.payload.error,
            isBusy: false,
        },
    }),
    [actions.api.data.busy]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isBusy: true,
        },
    }),

    [actions.api.data.fetch.success]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isFetching: false,
            lastUpdate: Date.now(),
            data: action.payload.data,
            isBusy: false,
        },
    }),

    [actions.api.data.update.request]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isUpdating: true,
        },
    }),
    [actions.api.data.update.failure]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isUpdating: false,
            errorOnUpdate: action.payload.error,
            isBusy: false,
        },
    }),
    [actions.api.data.update.success]: reduceUpdateSuccess,

    [actions.api.data.remove.request]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isRemoving: true,
        },
    }),
    [actions.api.data.remove.failure]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isRemoving: false,
            errorOnRemove: action.payload,
            isBusy: false,
        },
    }),
    [actions.api.data.remove.success]: reduceRemoveSuccess,

    [actions.api.data.put.request]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isUpdating: true,
            updatingIds: [...(state[action.payload.endpoint]?.updatingIds || []), action.payload.id],
            success: false,
        },
    }),

    [actions.api.data.put.failure]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isUpdating: false,
            errorOnUpdate: action.payload.error,
            updatingIds: state[action.payload.endpoint]?.updatingIds?.filter((id) => id !== action.payload.id) || [],
            isBusy: false,
            success: false,
        },
    }),

    [actions.api.data.put.success]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isUpdating: false,
            lastUpdate: Date.now(),
            updatingIds: state[action.payload.endpoint]?.updatingIds?.filter((id) => id !== action.payload.id) || [],
            isBusy: false,
            success: true,
        },
    }),

    [actions.api.data.reset]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            data: [],
            lastUpdate: null,
        },
    }),

    [actions.api.data.post.request]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isPosting: true,
        },
    }),

    [actions.api.data.post.failure]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isPosting: false,
            errorOnPost: action.payload.error,
            isBusy: false,
        },
    }),

    [actions.api.data.post.success]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...state[action.payload.endpoint],
            isPosting: false,
            lastUpdate: Date.now(),
            data: action.payload.data,
            isBusy: false,
        },
    }),
});
