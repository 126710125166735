import * as icons from '@mui/icons-material';
import { Drawer, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { getHiddenPanelParent, getMaxNamePanelLength, getPanelUri, panels } from '../panels';
import AnnotationDetails from '../panels/Annotation';
import DataSetsDetails from '../panels/Details';
import EventPanel from '../panels/Events';
import ProjectDetailsPanel from '../panels/ProjectDetails';

const drawerWidth = getMaxNamePanelLength(panels) * 8 + 93;

function SideBar(props) {
    const theme = useTheme();
    const {
        isOpen,
        authCancel,
        annotationDetails,
        annotationDetailsData,
        datasetId,
        annProcessing,
        projectId,
        projectSet,
        userPerms,
    } = props;

    const location = useLocation();

    const sxDrawer = () => {
        const c = {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            marginTop: theme.spacing(7),
            overflowX: 'hidden',
        };
        return c;
    };

    const sxDrawerOpen = () => {
        const c = isOpen
            ? {
                  width: drawerWidth,
                  transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                  }),
              }
            : {};
        return c;
    };

    const sxDrawerClose = () => {
        const c = !isOpen
            ? {
                  transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                  }),
                  overflowX: 'hidden',
                  width: theme.spacing(7),
              }
            : {};
        return c;
    };

    const sx = () => {
        const s = {
            ...sxDrawer(),
            ...sxDrawerOpen(),
            ...sxDrawerClose(),
        };
        return s;
    };

    const getUri = (panel) => {
        const params = {};
        const extra = {};
        if (panel.component.type === AnnotationDetails) {
            params.hash = annotationDetailsData;
        } else if (panel.component.type === DataSetsDetails) {
            params.id = datasetId;
        } else if (panel.component.type === ProjectDetailsPanel) {
            params.id = projectId;
        } else if (panel.component.type === EventPanel) {
            extra.start_date = 'default';
        }

        return getPanelUri(panel.component, params, extra);
    };

    const isSelected = (panel) => {
        const isParent = getHiddenPanelParent(location.pathname);

        if (isParent) {
            return getUri(panel) === getUri(isParent);
        }

        return location.pathname === getUri(panel);
    };

    const styles = {
        version: {
            textAlign: 'center',
            color: 'text.secondary',
            padding: 1,
            fontSize: '0.7rem',
        },
        separator: {
            height: 1,
            margin: '0 8px',
            borderColor: theme.palette.common.lightgray,
        },
        eventProgress: {
            height: '4px',
            borderRadius: '2px',
            backgroundColor: 'rgba(0,0,0,0)',
            '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.events.processing,
            },
        },
    };

    const panelFieldsRequired = [
        {
            type: AnnotationDetails,
            value: !annotationDetails,
        },
        {
            type: DataSetsDetails,
            value: !datasetId,
        },
        {
            type: ProjectDetailsPanel,
            value: !projectSet,
        },
    ];

    const hasPermission = (panel) => {
        if (panel.requiredPermission) {
            return userPerms[panel.requiredPermission] || false;
        }
        return true;
    };

    const isPanelDisabled = (panel) => {
        const panelField = panelFieldsRequired.find((panelField) => panelField.type === panel.component.type);
        return panelField?.value || false;
    };

    return (
        <Drawer variant='permanent' sx={sx()}>
            <List sx={sx()}>
                {panels.filter(hasPermission).map((panel, panelIdx) => (
                    <Box key={panelIdx}>
                        {panel.separator && <hr style={styles.separator} />}
                        <ListItem
                            button
                            component={NavLink}
                            to={getUri(panel)}
                            selected={isSelected(panel)}
                            disabled={isPanelDisabled(panel)}
                        >
                            <ListItemIcon>
                                <Box position='relative' display='inline-flex'>
                                    {panel.icon}
                                    {annProcessing && panel.annotationProcessingEvents && (
                                        <Box position='absolute' bottom={-4} right={0} width='100%'>
                                            <LinearProgress sx={styles.eventProgress} />
                                        </Box>
                                    )}
                                </Box>
                            </ListItemIcon>
                            <ListItemText primary={panel.name} />
                        </ListItem>
                    </Box>
                ))}
            </List>
            <List
                sx={{
                    ...sx(),
                    marginTop: 'auto',
                    padding: 0,
                }}
            >
                <Typography variant='subtitle2' noWrap sx={styles.version}>
                    {isOpen ? `DB v${props.dbVersion} | CVS v${props.cvsVersion}` : `v${props.dbVersion}`}
                </Typography>
                <ListItem button onClick={authCancel}>
                    <ListItemIcon>
                        <icons.ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                </ListItem>
            </List>
        </Drawer>
    );
}

SideBar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    authCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        datasetId: state.details.datasetId,
        annotationDetails: state.annotation.details.set,
        annotationDetailsData: state.annotation?.details?.data || {},
        projectId: state.project?.details?.data,
        projectSet: state.project?.details?.set,
        dbVersion: state.health.dbVersion || '...',
        cvsVersion: state.health.cvsVersion || '...',
        annProcessing: state.health.annProcessing || false,
        userPerms: state.auth?.user?.perms || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
