import { Box, Card, CircularProgress, Paper, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actions } from '../../../actions';
import { api } from '../../../services';
import EnhancedTableToolbar from '../../components/Table/EnhancedTableToolbar';
import AnnotationDetails from '../Annotation';
import { getPanelUri } from '../index';

function AnnotationSearchPanel(props) {
    const { annotationSearch, isLoading, annotationList, annotationQuery, annotationReset } = props;
    const theme = useTheme();

    const navigate = useNavigate();
    const [text, setText] = useState(null);

    const styles = useMemo(
        () => ({
            paper: {
                width: '100%',
                position: 'relative',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                padding: '0 0 14px 0',
                marginBottom: '4px',
            },
            card: {
                marginBottom: theme.spacing(2),
                backgroundColor: theme.palette.common.verylightgray,
                width: '100%',
            },
            title: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                width: 'calc(90vw - 300px)',
            },
            pos: {
                marginBottom: 4,
            },
            cardHeader: {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.common.mediumlightgray,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '100px',
            },
            cardBody: {
                padding: '16px 0px 16px 16px',
                width: '100%',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.common.lightgray,
                },
                display: 'block',
            },
            path: {
                fontSize: '16px',
            },
            word: {
                fontSize: '16px',
            },
            matchWord: {
                fontSize: '16px',
                color: theme.palette.primary.main,
            },
            noResults: {
                padding: theme.spacing(3),
                textAlign: 'center',
                color: theme.palette.text.secondary,
            },
            subtitle: {
                width: '100%',
                textAlign: 'center',
                color: theme.palette.text.secondary,
            },
            content: {
                maxHeight: 'calc(100vh - 326px)',
                overflowY: 'auto',
                marginLeft: theme.spacing(2),
            },
        }),
        [theme],
    );

    useEffect(() => {
        if (annotationQuery !== text) {
            if (text === '') {
                annotationReset();
                console.log(`reset ${annotationQuery}!==${text}`);
                return;
            }
            console.log(`search ${text}`);
            if (text) annotationSearch(text);
        }
    }, [annotationQuery, annotationSearch, annotationReset]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenAnnotation = useCallback(
        (hash) => {
            navigate(getPanelUri(<AnnotationDetails />, { hash }));
        },
        [navigate],
    );

    const renderLoading = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <CircularProgress />
        </div>
    );
    const handleAnnotationSearch = useCallback(
        (value) => {
            setText(value);
            if (isLoading) {
                return;
            }
            if (!value) {
                annotationReset();
                return;
            }
            if (value) annotationSearch(value);
        },
        [annotationSearch, annotationReset], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const annotationCards = useMemo(
        () =>
            annotationList.map((item, index) => (
                <Card key={index} style={styles.card}>
                    <Box display='flex' justifyContent='left' sx={{ width: '100%' }}>
                        <Tooltip title={'Score: ' + item.score.toFixed(5)} placement='top'>
                            <Box sx={styles.cardHeader}>
                                <Typography variant='h4' style={{ cursor: 'default' }}>
                                    {index + 1}
                                </Typography>
                            </Box>
                        </Tooltip>
                        <Box onClick={() => handleOpenAnnotation(item.annotation.hash)} sx={styles.cardBody}>
                            <Typography style={styles.title} color='textSecondary'>
                                {item?.annotation?.hash}
                            </Typography>
                            {item.highlights.map((highlight, idx) => (
                                <Typography key={idx} style={styles.pos} color='textSecondary'>
                                    <span style={styles.path}>{highlight.path}: </span>
                                    <span style={styles.matchWord}>{highlight.texts.join(', ')}</span>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </Card>
            )),
        [annotationList, styles, handleOpenAnnotation],
    );

    const renderContent = useMemo(() => {
        if (isLoading) {
            return renderLoading();
        } else if (annotationList.length > 0) {
            return (
                <>
                    <Typography variant='subtitle1' style={styles.subtitle}>
                        {annotationList.length} annotation{annotationList.length > 1 ? 's' : ''} found.
                    </Typography>
                    {annotationCards}
                </>
            );
        } else {
            return (
                <>
                    {text ? (
                        <Typography style={styles.noResults}>
                            No results found for `<strong>{text}</strong>`
                        </Typography>
                    ) : (
                        <Typography style={styles.noResults}>Enter a term to search for annotations.</Typography>
                    )}
                </>
            );
        }
    }, [isLoading, annotationList.length, text, styles, annotationCards]);

    return (
        <Paper style={styles.paper}>
            <EnhancedTableToolbar
                title={'Search Annotations'}
                isLoading={isLoading}
                setSearch={handleAnnotationSearch}
            />
            <Box sx={styles.content}>{renderContent}</Box>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return {
        annotationList: state.data[api.endpoints.annSearch]?.data?.data || [],
        annotationQuery: state.data[api.endpoints.annSearch]?.data?.query || null,
        isLoading:
            state.data[api.endpoints.annSearch]?.isFetching || state.data[api.endpoints.annSearch]?.isUpdating || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        annotationSearch: (value) =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annSearch,
                    q: value,
                }),
            ),
        annotationReset: () =>
            dispatch(
                actions.api.data.reset({
                    endpoint: api.endpoints.annSearch,
                }),
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationSearchPanel);
