import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function _normalizeDate(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

function LDateRange(props) {
    const { onChange, defaultValue, nodeId, setValidation, disableValidation } = props;

    const [updated, setUpdated] = useState(true);
    const [dateStart, setDateStart] = useState(_normalizeDate(new Date()));
    const [dateEnd, setDateEnd] = useState(_normalizeDate(new Date()));

    useEffect(() => {
        if (Array.isArray(defaultValue) && defaultValue.length === 2) {
            setDateStart(_normalizeDate(defaultValue[0]));
            setDateEnd(_normalizeDate(defaultValue[0]));
        }
    }, [setDateStart, setDateEnd, defaultValue]);

    const handleValidationClick = () => {
        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([dateStart, dateEnd]);
    };

    const handleDateStartChange = (newDate) => {
        if (!disableValidation) setValidation({ nodeId, validate: false });
        setDateStart(_normalizeDate(newDate));
        setUpdated(true);
    };

    const handleDateEndChange = (newDate) => {
        if (!disableValidation) setValidation({ nodeId, validate: false });
        setDateEnd(_normalizeDate(newDate));
        setUpdated(true);
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <DatePicker
                    value={dateStart}
                    maxDate={dateEnd}
                    onChange={handleDateStartChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item>
                <DatePicker
                    value={dateEnd}
                    minDate={dateStart}
                    onChange={handleDateEndChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item>
                <ValidationButton onClick={handleValidationClick} updated={updated} />
            </Grid>
        </BodyBuilderGrid>
    );
}

LDateRange.propTypes = {
    onChange: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
    };
};

export default connect(null, mapDispatchToProps)(LDateRange);
